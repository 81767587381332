// 代驾相关接口
import https from "../utils/server.js";
import http from "../utils/aixos";
// 司机列表
export const getUserSignInDetailList = params => http.get('/api/driver/searchDriverInfoList',{params})
// 司机详情
export const searchDriverInfoById = params => http.get('/api/driver/searchDriverInfoById',{params})
// 司机审核
export const handleDriverJoinRequest= params => http.post('/api/driver/handleDriverJoinRequest', params)
// 司机端订单列表
export const searchDriverOrderList = params => http.get('/api/driver/searchDriverOrderList',{params})
//代驾计费规则列表
export const searcDriverPayItemsList = params => http.get("/api/driver/searcDriverPayItemsList",{params})
// 通用设置
export const searchDriverCommonList = params => http.get("/api/driver/searchDriverCommonList",{params})
// 添加费用设置
export const insertDriverPayItems = params => http.post("/api/driver/insertDriverPayItems",params)
// 编辑费用设置
export const updateDriverPayItems = params => http.post("/api/driver/updateDriverPayItems",params)
// 编辑通用
export const updateDriverCommon = params => http.post("/api/driver/updateDriverCommon",params)
// 获取数据看板信息
export const getOrderData = params => https.get("/api/driver/getOrderData",{params})
// 代驾合同列表
export const searchDriverInsuranceRecordList = params => http.get("/api/driver/searchDriverInsuranceRecordList",{params})

