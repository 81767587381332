<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">代驾管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card style="height: 650px; overflow: auto">
      <!-- 分类搜索 -->
      <!-- <el-row style="margin-top: 15px">
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="订单编辑" style="width:160px"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
    <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="乘客手机号" style="width:160px"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
    <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="代驾手机号" style="width:160px"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
    <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="出发地" style="width:160px"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
    <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="目的地" style="width:160px"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
 
        <el-col :span="3.3">
          <el-select
            size="mini"
            style="width:160px"
            v-model="queryInfo.status"
            clearable
            placeholder="订单状态"
            @change="getSelectGoodsListStatus"
            class="mr15"
          >
          
            <el-option
              v-for="(item, index) in commodityStatus"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3.3">
          <el-select
            size="mini"
            style="width:160px"
            v-model="queryInfo.status"
            clearable
            placeholder="支付方式"
            @change="getSelectGoodsListStatus"
            class="mr15"
          >
            <el-option
              v-for="(item, index) in commodityStatus"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <!--商品数据展示区域-->
      <el-table
        :data="tableData"
        style="width: 100%"
        height="500"
        ref="singleTable"
        highlight-current-row
        border
        row-key="id"
         v-loading="loading"
        stripe
      >
     <el-table-column type="selection" width="50"></el-table-column>
     <!-- <el-table-column prop="name" label="序号" width="50"  show-overflow-tooltip></el-table-column> -->
     <el-table-column prop="id" label="订单编号" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column prop="userMobile" label="乘客手机号" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column prop="userName" label="乘客昵称" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column prop="driverInfoJson.mobile" label="代驾手机号" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column prop="driverInfoJson.name" label="代驾昵称" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column label="车况" width="180" align="center" show-overflow-tooltip>
      <template slot-scope="scope" >
        <el-button @click="modificationressVisiblier(scope.row.carSituation)" size="mini" type="text" :disabled="!(scope.row.carSituation)"  >查看</el-button>
         </template>
    </el-table-column>
      <el-table-column prop="cityName" label="城市" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column prop="startPlaceJson.pcdName" label="出发地" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column prop="endPlaceJson.pcdName" label="目的地" width="180" show-overflow-tooltip></el-table-column>
     <el-table-column  label="订单状态" width="180" show-overflow-tooltip>
      <template slot-scope="scope" >
          <span v-if="scope.row.orderStatus==0">待接单</span>
          <span v-else-if="scope.row.orderStatus==1">已接单</span>
          <span v-else-if="scope.row.orderStatus==2">司机到起点</span>
          <span v-else-if="scope.row.orderStatus==3">出发</span>
          <span v-else-if="scope.row.orderStatus==4">取消订单</span>
          <span v-else-if="scope.row.orderStatus==5">订单取消但需要支付</span>
          <span v-else-if="scope.row.orderStatus==6">完成行驶</span>
        
          </template>
     
     </el-table-column>
     <el-table-column label="起步里程" width="180" show-overflow-tooltip>
       <template slot-scope="scope" >
          <span v-if="scope.row.lowestDistance">{{scope.row.lowestDistance}}km</span>
          </template>
     </el-table-column>
     <el-table-column prop="lowestFee" label="起步价" width="180" show-overflow-tooltip>
      <template slot-scope="scope" >
          <span v-if="scope.row.lowestFee">{{scope.row.lowestFee}}元</span>
          </template>
     </el-table-column>
     <el-table-column label="里程" width="180" show-overflow-tooltip>
       <template slot-scope="scope" >
          <span v-if="scope.row.overDistance">{{scope.row.overDistance}}km</span>
          </template>
     </el-table-column>
     <el-table-column prop="distanceFee" label="里程费" width="180" show-overflow-tooltip>
     <template slot-scope="scope" >
          <span v-if="scope.row.distanceFee">{{scope.row.distanceFee}}元</span>
          </template>
     </el-table-column>
     <el-table-column prop="waitTime" label="等待时间" width="180" show-overflow-tooltip>
       <template slot-scope="scope" >
          <span v-if="scope.row.waitTime">{{scope.row.waitTime}}分钟</span>
          </template>
     </el-table-column>
     <el-table-column prop="waitFee" label="等待费用" width="180" show-overflow-tooltip>
        <template slot-scope="scope" >
          <span v-if="scope.row.waitFee">{{scope.row.waitFee}}元</span>
          </template>
     </el-table-column>
     <el-table-column prop="totalFee" label="合计" width="180" show-overflow-tooltip></el-table-column>
      <el-table-column  label="支付状态" width="180" show-overflow-tooltip >
        <template slot-scope="scope" >
          <span v-if="scope.row.payStatus==0">未支付</span>
          <span v-else-if="scope.row.payStatus==1">已支付</span>
        
          </template>
      </el-table-column>
     <el-table-column prop="signProvince" label="支付方式" width="180" show-overflow-tooltip >
          <template slot-scope="scope" >
          <span v-if="scope.row.payWay==0">支付宝</span>
          <span v-else-if="scope.row.payWay==1">微信</span>
        
          </template>
          </el-table-column>
             <el-table-column prop="createTime" label="订单开始时间" width="180" show-overflow-tooltip>
     </el-table-column>
     <el-table-column prop="finishTime" label="订单结束时间" width="180" show-overflow-tooltip>
      </el-table-column>
        <!-- <el-table-column label="操作" min-width="100" fixed="right">
          <el-button @click="dialogVisible = true" size="mini" type="primary" >取消</el-button> 
        </el-table-column> -->
      </el-table>
      
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10,]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
     <!-- 列表大图展示 -->
     <el-dialog :visible.sync="dialogimg"  :append-to-body="true" >
      <img width="100%" :src="dialogimgsrc" alt="" />
    </el-dialog>
       <!-- 车况 -->
<el-dialog  title="车况图片" :visible.sync="activity"   :append-to-body="true" >

   <img style="margin-left:20px ;"  class="carSituation" v-for="i in carSituation " :key="i" :src="i" @click="ondialogimg(i)" alt="">

    </el-dialog>
  </div>
</template>

<script>
// 引入拖动列表组件
import time from "@/utils/time";
import dataFunction from "@/utils/dataFunction";
import { searchDriverOrderList } from "../../api/substitute";

export default {
  name: "UsesignList",
  data() {
    return {
      dialogimg:false,
      dialogimgsrc:"",
      activity:false,
      // 车况图片
      carSituation:[],
      gridData: [],
      // 弹窗页面设置
      setType: 0,
      loading: false,
      // 新建区域弹窗
      innerVisible: false,
      form: {},
      fileList: [],
      outerVisible: false,
      radio: "1",
      commodityStatus: [
   
      ],
      // 查找商品列表参数对象
      queryInfo: {
      page:1,
      size:10
      },
      time: "",
adddatafo:{

},
      total: 0,
      tableData: [],
    };
  },
  created() {
    this.getGoodsList();
  },
  methods: {
    ondialogimg(i){
   this.dialogimgsrc=i
   this.dialogimg=true
    },
    // 查看车况图片
    modificationressVisiblier(e){
      this.carSituation=[]
      JSON.parse(e).forEach(element => {
        this.carSituation.push(element.split("?")[0])
      });
   this.activity=true
    },
    /*
     * 页面加载请求所有的订单数据
     * 默认请求第一页 十条数据
     * */
    async getGoodsList() {
        const { data: res } = await searchDriverOrderList(this.queryInfo);
        if (res.code !== 200) return this.$message.error("订单列表获取错误");

        this.tableData = res.body.list;
      
          this.tableData.forEach((element,i) => {
            this.tableData[i].endPlaceJson=JSON.parse(element.endPlaceJson)
           this.tableData[i].startPlaceJson=JSON.parse(element.startPlaceJson)
           this.tableData[i].driverInfoJson  = JSON.parse(element.driverInfoJson)
           this.tableData[i].createTime = time(element.createTime)
           if(element.finishTime){
            this.tableData[i].finishTime= time(element.finishTime)
           }
           
           
          });
        this.total = res.body.total;

    },

    handleCurrentChange(data) {
      this.queryInfo.page = data;
      this.getGoodsList();
    },
    // 省份城市搜索
    handleSizeChange(newSize) {
      this.queryInfo.size = newSize;
      this.getGoodsList();
    },
    // 日期搜索
    dateHandle() {
      this.queryInfo.pageNum = 1;
      this.queryInfo.time = dataFunction(this.time);
      this.getGoodsList();
    },
    //选择省
    onprovince(data) {
      this.queryInfo.pageNum = 1;
      this.queryInfo.province = data.value;
      this.getGoodsList();
    },
    // 选择市
    oncity(data) {
      this.queryInfo.pageNum = 1;
      this.queryInfo.city = data.value;
      this.getGoodsList();
    },

    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style scoped lang="scss">
  .carSituation{
    width: 300px;
    height: 200px;
  
  }
.mr15 {
  margin-right: 18px;
}

p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}


.v-distpicker ::v-deep select {
  height: 35px !important;
 
  padding-top: 0.4rem;
  font-size: 14px;
}
.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
</style>